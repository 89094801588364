import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import Loading from '../components/loading';

import '../components/css/main.css';

const Viewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [admin_token, setAdminToken] = useState('');
    let { slug } = useParams();
    let { id } = useParams();
    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'admin_session')
            .then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '' && res.data.admin_token !== '') {
                    setLoading(false);
                    setName(res.data.name);
                    setEmail(res.data.email);
                    setAdminToken(res.data.admin_token);
                } else {
                    window.location = '/signin';
                }
            })
            .catch(() => {
                window.location = '/signin';
            });
    }, [props.node_server_axios, slug, id]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className='main index'>
                <Header login={true} name={name} admin_token={admin_token} email={email} node_server_axios={props.node_server_axios} />
                <div className='body-wrapper'>
                    <object data={"https://pronetin-new.s3.ap-south-1.amazonaws.com/verification/" + slug + "/" + id + '#view=FitH'} type="application/pdf" className="pdf-full-page">
                        <embed src={"https://pronetin-new.s3.ap-south-1.amazonaws.com/verification/" + slug + "/" + id} type="application/pdf" />
                    </object>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Viewer;