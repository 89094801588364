import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';

class DesignRequestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            elements: [],
            modalState: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_print_requests').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    removeDesignRequest = (batch_id) => {
        axios.post(this.props.node_server_axios + 'remove_print_request', {
            params: {
                batch_id: batch_id,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    window.location = "/printrequests";
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin Verification<label>Organisation requests for pronetin verification</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            <div className='logs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Organisatio ID</th>
                                            <th>User</th>
                                            <th>Action</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.elements.map((element, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{element.org_id}</td>
                                                    <td>{element.email}</td>
                                                    <td><button onClick={() => { window.location = '/download/' + element.batch_id + "/" + element.email + "/" + element.org_id }}>Go To Batch</button></td>
                                                    <td><button onClick={() => this.removeDesignRequest(element.batch_id)} style={{ backgroundColor: 'red' }}>Remove</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DesignRequestComponent;