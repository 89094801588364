import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            elements: [],
            modalState: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_promocode_list').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    addPromoCode = () => {
        axios.post(this.props.node_server_axios + 'add_promo_code', {
            params: {
                promo_code: this.state.promo_code,
                credits: this.state.promo_credits,
                expiry: this.state.promo_expiry,
                max_limit: this.state.promo_max_limit,
                message: this.state.promo_message,
            }
        })
    }

    removePromoCode = (promo_code) => {
        axios.post(this.props.node_server_axios + 'remove_promo_code', {
            params: {
                promo_code: promo_code,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    window.location = "/promocodes";
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    addPromoModal = () => {
        this.setState({
            modalState: !this.state.modalState,
        })
    }

    closeModal = (e) => {
        if (e.target.className === 'modal') {
            this.setState({
                modalState: false,
            })
        }
    }

    changePromoCode = (e) => {
        this.setState({
            promo_code: e.target.value,
        })
    }

    changeCredits = (e) => {
        this.setState({
            promo_credits: e.target.value,
        })
    }

    changeCouponLimit = (e) => {
        this.setState({
            promo_max_limit: e.target.value,
        })
    }

    changeMessage = (e) => {
        this.setState({
            promo_message: e.target.value,
        })
    }

    changeExpiry = (e) => {
        this.setState({
            promo_expiry: e.target.value,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin Verification<label>Organisation requests for pronetin verification</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            <button className='logs-btn' onClick={this.addPromoModal}>Add New Promo Code</button>
                            <div className='logs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Promo Code</th>
                                            <th>Credits</th>
                                            <th>Redeem Count</th>
                                            <th>Max Coupons</th>
                                            <th>Expiry</th>
                                            <th>Message</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.elements.map((element, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{element.promo_code}</td>
                                                    <td>{element.credits}</td>
                                                    <td>{element.count}</td>
                                                    <td>{element.max_limit}</td>
                                                    <td>{element.expiry}</td>
                                                    <td>{element.message}</td>
                                                    <td><button onClick={() => this.removePromoCode(element.promo_code)}>Delete</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
                {this.state.modalState && (
                    <div className='modal' onClick={this.closeModal}>
                        <form className="content promo" onSubmit={this.addPromoCode}>
                            <h2>Add New Promo Code</h2>
                            <p>Promocode</p>
                            <input type="text" className='promo_code' placeholder='WELCOME100' value={this.state.promo_code} onChange={this.changePromoCode} />
                            <p style={{ color: '#DF7184', fontSize: '12px' }}>End the code with number of credits</p>
                            <p>Credits</p>
                            <input type="number" min='1' max='2500' value={this.state.promo_credits} onChange={this.changeCredits} />
                            <p>Coupon Limit</p>
                            <input type="number" min='1' max='9999' value={this.state.promo_max_limit} onChange={this.changeCouponLimit} />
                            <p>Message</p>
                            <input type="text" placeholder='Introductory offer of 10 credits added' value={this.state.promo_message} onChange={this.changeMessage} />
                            <p>Expiry</p>
                            <input type="date" value={this.state.promo_expiry} onChange={this.changeExpiry} />
                            <button type='submit'>Add +</button>
                        </form>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default TileComponent;