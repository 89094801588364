import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';

class DesignRequestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            elements: [],
            modalState: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    convertYYMMDDToDate(YYMMDD) {
        const year = parseInt(YYMMDD.substring(0, 2)) + 2000;
        const month = parseInt(YYMMDD.substring(2, 4)) - 1;
        const day = parseInt(YYMMDD.substring(4, 6));
        const date = new Date(year, month, day);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substring(2)}`;

        return formattedDate;
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_design_requests').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    removeDesignRequest = (email) => {
        axios.post(this.props.node_server_axios + 'remove_design_request', {
            params: {
                email: email,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    window.location = "/designrequests";
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin Verification<label>Organisation requests for pronetin verification</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            <div className='logs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.elements.map((element, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{this.convertYYMMDDToDate(element.itime)}</td>
                                                    <td>{element.email}</td>
                                                    <td><button onClick={() => this.removeDesignRequest(element.email)}>Remove</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DesignRequestComponent;