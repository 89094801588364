import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            fileList: [],
            elements: [],
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_enquiry_list').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    updateEnquiry = (e, email) => {
        e.preventDefault();
        const callbackReasonTextarea = e.target.querySelector('textarea[placeholder="Callback Reason"]');
        const followupStatusTextarea = e.target.querySelector('textarea[placeholder="Followup status"]');
        const support_reason = callbackReasonTextarea.value;
        const support_status = followupStatusTextarea.value;

        axios.post(this.props.node_server_axios + 'admin_update_enquiry', {
            params: {
                email: email,
                support_status: support_status,
                support_reason: support_reason,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Updated Enquiry for " + email,
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    deleteEnquiry = (email) => {
        axios.post(this.props.node_server_axios + 'admin_resolve_enquiry', {
            params: {
                email: email,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Resolved enquiry for " + email,
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.email !== email),
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Enquiries<label>Callback enquiries by registered users</label></h1>
                            </div>
                        </div>
                        <div className='content-tile marketplace'>
                            {this.state.elements.map((element, index) => {
                                return (
                                    <div className='action-container-1' key={index}>
                                        <div className='container'>
                                            <label>Callback Details</label>
                                            <h2>{element.support_name}</h2>
                                            <p>{element.email}</p>
                                            <h3>{element.support_phone}</h3>
                                        </div>
                                        <div className='container'>
                                            <label>About</label>
                                            <h3>{element.support_type}</h3>
                                            <p>Support open : {element.date_time}</p>
                                            <p>Support time : {element.support_time}</p>
                                            <p>Token : {element.support_token}</p>
                                        </div>
                                        <form className='container' onSubmit={(e) => this.updateEnquiry(e, element.email)}>
                                            <label>Status</label>
                                            <textarea placeholder='Callback Reason' defaultValue={element.support_reason}></textarea>
                                            <textarea placeholder='Followup status' defaultValue={element.support_status}></textarea>
                                            <button className='file-block' type='submit'>Update</button>
                                        </form>
                                        <div className='container'>
                                            <button onClick={() => this.deleteEnquiry(element.email)}>Resolve</button>
                                        </div>
                                    </div>
                                )
                            })}
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TileComponent;