import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const ReactPage = ReactDOM.createRoot(document.getElementById('root'));

ReactPage.render(
  <React.Fragment>
    {/* (Localhost Only) */}
    {/* <App node_server_axios={'http://localhost:8080/api/'} /> */}
    {/* <App node_server_axios={'http://192.168.137.22:8080/api/'} /> */}
    <App node_server_axios={'https://pronetin.in:8080/api/'} />
  </React.Fragment>
);