import React from 'react';
import axios from 'axios';

import Header from '../components/header';
import SideNav from '../components/sidenav';
import Element from '../components/promo_codes';
import Loading from '../components/loading';

import '../components/css/main.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            admin_token: '',
            email: '',
            name: '',
        }
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_session').then((res) => {
            if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '' && res.data.admin_token !== '') {
                this.setState({
                    loading: false,
                    admin_token: res.data.admin_token,
                    email: res.data.email,
                    name: res.data.name,
                });
            } else {
                window.location = "/signin";
            }
        }).catch(() => {
            window.location = "/signin";
        })
    }


    render() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <div className="main">
                        <Header />
                        <Loading />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className='main index'>
                        <Header login={true} name={this.state.name} admin_token={this.state.admin_token} email={this.state.email} node_server_axios={this.props.node_server_axios} />
                        <div className='body-wrapper'>
                            <SideNav name={this.state.name} email={this.state.email} selected={"promocodes"} admin_token={this.state.admin_token} node_server_axios={this.props.node_server_axios} />
                            <Element email={this.state.email} node_server_axios={this.props.node_server_axios} />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}
export default Index;