import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import Loading from '../components/loading';
import Element from '../components/download_batch';
import SideNav from '../components/sidenav';

import '../components/css/main.css';

const Viewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [admin_token, setAdminToken] = useState('');
    let { slug } = useParams();
    let { useremail } = useParams();
    let { orgId } = useParams();

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.post(props.node_server_axios + 'admin_session')
            .then((res) => {
                if (res.data !== undefined && res.data.email !== undefined && res.data.email !== '' && res.data.admin_token !== '') {
                    setLoading(false);
                    setName(res.data.name);
                    setEmail(res.data.email);
                    setAdminToken(res.data.admin_token);
                } else {
                    window.location = '/signin';
                }
            })
            .catch(() => {
                window.location = '/signin';
            });
    }, [props.node_server_axios, slug, useremail, orgId]);

    return loading ? (
        <React.Fragment>
            <div className="main">
                <Header />
                <Loading />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className='main index'>
                <Header login={true} name={name} admin_token={admin_token} email={email} node_server_axios={props.node_server_axios} />
                <div className='body-wrapper'>
                    <SideNav name={name} email={email} selected={"printrequests"} admin_token={admin_token} node_server_axios={props.node_server_axios} />
                    <Element email={email} name={name} batch_id={slug} useremail={useremail} org_id={orgId} node_server_axios={props.node_server_axios} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Viewer;