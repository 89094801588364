import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import WarningAction from '../components/warning_action';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            fileList: [],
            elements: [],
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_org_approval_requests').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    updateVerification = (e, org_email) => {
        e.preventDefault();
        const selectElement = e.target.querySelector('select');
        const textareaElement = e.target.querySelector('textarea');
        const selectedStatus = selectElement.value;
        const message = textareaElement.value;

        axios.post(this.props.node_server_axios + 'update_org_verfication', {
            params: {
                org_email: org_email,
                status: selectedStatus,
                message: message,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Updated Organisation Verfication for " + org_email,
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    acceptVerification = async (org_email, org_domain, org_name) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Accept Organisation",
                subtext: "This action cannot be reverted, organisation will be created for (" + org_email + "). Do you wish to continue?",
                type: "success",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'accept_org_verfication', {
            params: {
                org_name: org_name,
                org_email: org_email,
                org_domain: org_domain,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Accepted Organisation Verfication for " + org_email,
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.email !== org_email),
                    })
                } else if (res.data.message === 'organiser') {
                    this.setState({
                        warning: {
                            message: "This user is already an organiser.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.email !== org_email),
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    rejectVerification = async (org_email) => {
        //--------------------------Warning Action Trigger Start------------------------------
        let proceed_function = true;
        this.setState({
            warning_action: {
                message: "Reject Verification",
                subtext: "Once rejected applicant (" + org_email + ") has to re-apply. Do you wish to continue?",
                type: "warning",
                proceed_action: "true",
                proceed_function: proceed_function,
                back_action: false,
                close: true,
            }
        })
        if (proceed_function) {
            await new Promise((resolve) => {
                this.resumeFunction = resolve;
            });
            this.setState({
                warning_action: {
                    message: "",
                    subtext: "",
                    type: "warning",
                    proceed_action: false,
                    proceed_function: false,
                    back_action: false,
                    close: true,
                },
            })
        }
        //--------------------------Warning Action Trigger End------------------------------
        axios.post(this.props.node_server_axios + 'reject_org_verfication', {
            params: {
                org_email: org_email,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Rejected Organisation Verfication for " + org_email,
                            type: "waring",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.email !== org_email),
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Organisation Approvals<label>Requests for organisation account</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            {this.state.elements.map((element, index) => {
                                return (
                                    <div className='action-container-1' key={index}>
                                        <div className='container'>
                                            <label>Organisation Details</label>
                                            <h2>{element.org_name}</h2>
                                            <p>{element.email}</p>
                                            <p>{element.domain}</p>
                                        </div>
                                        <div className='container'>
                                            <label>About</label>
                                            <p>Date : {element.date}</p>
                                            <p>Time : {element.time}</p>
                                        </div>
                                        <form className='container' onSubmit={(e) => this.updateVerification(e, element.email)}>
                                            <label>Status</label>
                                            <select defaultValue={element.status}>
                                                <option value='input required'>Input required</option>
                                                <option value='could not confirm ownership'>Could not confirm ownership</option>
                                                <option value='pending'>Pending</option>
                                            </select>
                                            <textarea placeholder='Message' defaultValue={element.message} style={{ resize: 'none' }}></textarea>
                                            <button className='file-block' type='submit'>Update</button>
                                        </form>
                                        <div className='container'>
                                            <button onClick={() => this.acceptVerification(element.email, element.domain, element.org_name)}>Accept</button>
                                            <button onClick={() => this.rejectVerification(element.email)}>Reject</button>
                                        </div>
                                    </div>
                                )
                            })}
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                            {
                                this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TileComponent;