import React from 'react';
import './css/signin.css';
import SigninIcon from './resources/lf-1.png';
// import GoogleButton from './googleSigin';
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: '',
            },
            response: '',
            warningActive: '',
            redirectStatus: '',
        };
    }

    checkValidation = (e) => {
        var regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { name, value } = e.target;
        let isError = { ...this.state.isError };
        switch (name) {
            case "email":
                isError.email = regEmail.test(value) ? "" : "Email address is invalid";
                break;
            case "password":
                isError.password = value.length < 8 ? "Atleast 8 characaters required" : "";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();

        axios.defaults.withCredentials = true;

        axios.post(this.props.node_server_axios + 'admin_signin', {
            params: {
                data: {
                    email: event.target.email.value,
                    password: event.target.password.value,
                },
                error: this.state.isError
            },
        }).then(res => {
            if (res.data.message !== '') {
                this.setState({ response: res.data.message, warningActive: true })
            }
            if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                window.location = res.data.redirect;
            }
        });
    }

    resendVerification = (event) => {
        event.preventDefault();
        axios.post(this.props.node_server_axios + 'mailer-email-verification', {
            params: {
                data: {
                    email: this.state.email,
                },
            },
        }).then(res => {
            if (res.data.message === 'success') {
                this.setState({ response: 'Email Sent', warningActive: false })
            }
        });
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectStatus = searchParams.get("o");
        this.setState({ redirectStatus });
    }

    render() {
        const { isError } = this.state;
        return (
            <React.Fragment>
                <header className="wrapper">
                    {this.state.redirectStatus === 'verified' && (<div className='redirect-status'><i className="fa-regular fa-circle-check"></i> Email verified. Signin to continue</div>)}
                    {this.state.redirectStatus === 'emailsend' && (<div className='redirect-status'><i className="fa-regular fa-circle-check"></i> Verification email sent. Please check your inbox </div>)}
                    {this.state.redirectStatus === 'failed' && (<div className='redirect-status failed'><i className="fa-regular fa-circle-xmark"></i> Failed to complete action</div>)}
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className="container">
                            <img src={SigninIcon} className="userimage" alt="user signin"></img>
                            <h1>Sign In</h1>
                            <div className='relative block w-100 flex-cr'>
                                {isError.email.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.email}</span></div>
                                )}
                                <input type="email" name='email' className={this.state.response !== '' && this.state.email === '' ? 'error lowercase' : 'lowercase'} placeholder="johndoe@pronetin.com" onChange={this.checkValidation} />
                                <label>Email</label>
                            </div>
                            {this.state.redirectStatus === 'organisation' && (<p className='warning'>Work email required for issuing certificate</p>)}
                            <div className='relative block w-100 flex-cr'>
                                {isError.password.length > 0 && (
                                    <div className='tooltip'><i className='fa-solid fa-circle-exclamation'></i><span className="tooltiptext">{isError.password}</span></div>
                                )}
                                <input type="password" name='password' autoComplete='on' placeholder="********" className={this.state.response !== '' && this.state.password === '' ? 'error' : ''} onChange={this.checkValidation} />
                                <label>Password</label>
                            </div>
                            <p className={this.state.warningActive ? 'warning block' : 'warning none'}><i className='fa-solid fa-circle-exclamation'></i> {this.state.response}</p>
                            {
                                this.state.response === 'Please check your email and verify your account to continue.' && (<a href='foo' className='action-btn' onClick={this.resendVerification}>Resend Email</a>)
                            }
                            {
                                this.state.response === 'Email Sent' && (<p className='success'><i className="fa-regular fa-circle-check"></i> Email Sent</p>)
                            }
                            <p><a className='secondary' href='/forgot'>Forgot Password</a></p>
                            <button type='submit'>Sign In</button>
                            <p>Don't Have an account?&nbsp;&nbsp;<a className='secondary' href='/signup'>Sign Up <i className='fa-solid fa-user-plus'></i></a></p>
                            <p><a href='foo'>Introduction</a> . <a href='/help' target='_blank'>Help</a> . <a href='./privacypolicy' target='_blank'>Privacy Policy</a></p>
                            {/* <div id="googleButton"><GoogleButton /></div> */}
                        </div>
                    </form>
                </header>
            </React.Fragment>
        );
    }
};

export default App;