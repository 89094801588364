import './css/sidenav.css';
// import axios from 'axios';

import React from 'react';

class SidenavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minNav: localStorage.getItem("minNav") === null ? false : JSON.parse(localStorage.getItem("minNav")),
        }
    }

    organisationRender = (value) => {
        if (value === 'superadmin') {
            return (
                <div className='module'>
                    {!this.state.minNav ? <label>Super Admin</label> : <label>Sup</label>}
                    <a className={this.props.selected === 'admins' ? 'nav selected' : 'nav'} href="/manageadmins">
                        <i className="fa-solid fa-user-shield"></i>
                        {!this.state.minNav && ('Manage Admins')}
                    </a>
                </div>
            );
        } else {
            return null;
        }
    }

    toggleMenu = (e) => {
        e.preventDefault();
        var mobileNavModal = document.getElementById('mobile-nav-modal');
        if (mobileNavModal.style.display !== 'flex') {
            mobileNavModal.style.display = 'flex';
        } else {
            mobileNavModal.style.display = 'none';
        }
    }

    toggleMinNav = () => {
        localStorage.setItem("minNav", !this.state.minNav);
        this.setState({
            minNav: !this.state.minNav,
        });
    }

    render() {
        return (
            <div className={!this.state.minNav ? "sidenav" : "sidenav min-sidenav"}>
                <div className='mobile'>
                    <a href='../' className='nav-ico active'><i className="fa-solid fa-house"></i><p>Home</p></a>
                    <a href='foo' className='nav-ico' onClick={this.toggleMenu}><i className="fa-solid fa-bars"></i><p>Menu</p></a>
                </div>
                <div className="container" id='mobile-nav-modal'>
                    <div className='profile'>
                        <div className='user-sec'>
                            <div className={'userbadge ' + this.props.admin_token}>{this.props.admin_token.charAt(0)}</div>
                            {!this.state.minNav && (<h1>{this.props.name}</h1>)}
                        </div>
                    </div>

                    {/* <form method='POST' className='search'>
                        <input type='text' name='search' placeholder='Search' />
                        <button type='submit'><i className="fa-solid fa-magnifying-glass"></i></button>
                    </form> */}

                    <div className='container scroll'>
                        <div className='module'>
                            {!this.state.minNav ? <label>Admin</label> : <label>Adm</label>}
                            {/* <a className={this.props.selected === 'userdata' ? 'nav selected' : 'nav'} href="/">
                                <i className="fa-solid fa-users"></i>
                                {!this.state.minNav && ('User Data')}
                            </a>
                            <a className={this.props.selected === 'abtesting' ? 'nav selected' : 'nav'} href="/mydocuments">
                                <i className="fa-solid fa-circle-half-stroke"></i>
                                {!this.state.minNav && ('A/B Testing')}
                            </a> */}
                            <a className={this.props.selected === 'proapprovals' ? 'nav selected' : 'nav'} href="/">
                                <i className="fa-solid fa-person-circle-check"></i>
                                {!this.state.minNav && ('Pronetin Verification')}
                            </a>
                            <a className={this.props.selected === 'orgapprovals' ? 'nav selected' : 'nav'} href="/orgapprovals">
                                <i className="fa-solid fa-circle-check"></i>
                                {!this.state.minNav && ('Organisation Approvals')}
                            </a>
                            {/* <a className={this.props.selected === 'ideaproposals' ? 'nav selected' : 'nav'} href="/approvals">
                                <i className="fa-regular fa-lightbulb"></i>
                                {!this.state.minNav && ('Idea Proposals')}
                            </a> */}
                            <a className={this.props.selected === 'enquiries' ? 'nav selected' : 'nav'} href="/enquiries">
                                <i className="fa-regular fa-address-book"></i>
                                {!this.state.minNav && ('Enquiries')}
                            </a>
                            <a className={this.props.selected === 'promocodes' ? 'nav selected' : 'nav'} href="/promocodes">
                                <i className="fa-solid fa-coins"></i>
                                {!this.state.minNav && ('Promo Codes')}
                            </a>
                            <a className={this.props.selected === 'designrequests' ? 'nav selected' : 'nav'} href="/designrequests">
                                <i className="fa-solid fa-brush"></i>
                                {!this.state.minNav && ('Design Requests')}
                            </a>
                            <a className={this.props.selected === 'printrequests' ? 'nav selected' : 'nav'} href="/printrequests">
                                <i className="fa-solid fa-print"></i>
                                {!this.state.minNav && ('Print Requests')}
                            </a>
                            <a className={this.props.selected === 'deletecertificates' ? 'nav selected' : 'nav'} href="/deletecertificates">
                                <i className="fa-solid fa-trash"></i>
                                {!this.state.minNav && ('Delete Certificates')}
                            </a>
                            {/* <a className={this.props.selected === 'feedbacks' ? 'nav selected' : 'nav'} href="/approvals">
                                <i className="fa-regular fa-comment-dots"></i>
                                {!this.state.minNav && ('Feedbacks')}
                            </a> */}
                        </div>
                        {
                            this.organisationRender(this.props.admin_token)
                        }
                        <div className='fg-1'></div>
                        {!this.state.minNav && (
                            <div className='copyright'>
                                <b>PRONETIN &copy; 2022</b>
                                <p>Aerobits Developers Pvt. Ltd.</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='min-sidenav-btn' onClick={this.toggleMinNav}>
                    {this.state.minNav ? <i className="fa-solid fa-angle-right"></i> : <i className="fa-solid fa-angle-left"></i>}
                </div>
            </div>
        );
    }
}

export default SidenavComponent;