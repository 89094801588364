import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages';
import Signin from './pages/signin';
import OrgApprovals from './pages/org_approvals';
import Enquiries from './pages/enquiries';
import PromoCodes from './pages/promo_codes';
import DesignRequests from './pages/design_requests';
import PrintRequests from './pages/print_requests';
import DownloadBatch from './pages/download_batch';
import DeleteCertificates from './pages/delete_certficates';
import FileView from './pages/file_view';

function App(props) {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home node_server_axios={props.node_server_axios} />} />
        <Route path='/signin' element={<Signin node_server_axios={props.node_server_axios} />} />
        <Route path='/orgapprovals' element={<OrgApprovals node_server_axios={props.node_server_axios} />} />
        <Route path='/enquiries' element={<Enquiries node_server_axios={props.node_server_axios} />} />
        <Route path='/promocodes' element={<PromoCodes node_server_axios={props.node_server_axios} />} />
        <Route path='/designrequests' element={<DesignRequests node_server_axios={props.node_server_axios} />} />
        <Route path='/printrequests' element={<PrintRequests node_server_axios={props.node_server_axios} />} />
        <Route path='/download/:slug/:useremail/:orgId' element={<DownloadBatch node_server_axios={props.node_server_axios} />} />
        <Route path='/deletecertificates' element={<DeleteCertificates node_server_axios={props.node_server_axios} />} />
        <Route path='/verification/:slug/:id' element={<FileView node_server_axios={props.node_server_axios} />} />

        {/* Redirect all unknown routes to the home page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
