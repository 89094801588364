import logo from '../images/static/Group 11.png';
import React from 'react';
import './css/header.css';
import axios from 'axios';

import WarningAction from './warning_action';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.targetRef = React.createRef();
        this.state = {
            profileImage: false,
            warning_action: {
                message: "",
                subtext: "",
                type: "warning",
                proceed_action: false,
                proceed_function: false,
                back_action: false,
                close: true,
            },
            UserNav: false,
        };
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleOutsideClick);
        let profileIconColor = localStorage.getItem('profile-icon-color');
        if (profileIconColor !== null) {
            document.documentElement.style.setProperty('--random-color', profileIconColor);
        } else {
            this.setRandomColor();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    fetchWarningActionState = (data) => {
        this.setState({
            warning_action: {
                message: data,
            }
        })
    }

    Signout = event => {
        event.preventDefault();
        localStorage.removeItem('user_info_skip');
        localStorage.removeItem('profile-icon-color');
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'signout').then((res) => {
            if (res.data.redirect !== '' && res.data.redirect !== undefined) {
                window.location = res.data.redirect;
            }
        })
    }

    goHome = async () => {
        const path = window.location.pathname;
        const segments = path.split('/');
        if (segments[segments.length - 1] === 'issue') {
            //--------------------------Warning Action Trigger Start------------------------------
            let proceed_function = true;
            this.setState({
                warning_action: {
                    message: "Return to Home",
                    subtext: "Documents attached and recipient details will be lost. Do you wish to continue?",
                    type: "warning",
                    proceed_action: "true",
                    proceed_function: proceed_function,
                    back_action: false,
                    close: true,
                }
            })
            if (proceed_function) {
                await new Promise((resolve) => {
                    this.resumeFunction = resolve;
                });
                this.setState({
                    warning_action: {
                        message: "",
                        subtext: "",
                        type: "warning",
                        proceed_action: false,
                        proceed_function: false,
                        back_action: false,
                        close: true,
                    },
                })
            }
            //--------------------------Warning Action Trigger End------------------------------
        }
        window.location.href = './';
    }

    toggleUserNav = () => {
        this.setState({
            UserNav: !this.state.UserNav,
        });
    }

    handleOutsideClick = (event) => {
        const targetElement = event.target;
        const { current: targetDiv } = this.targetRef;

        if (targetDiv && !targetDiv.contains(targetElement)) {
            this.setState({
                UserNav: false,
            });
        }
    };

    randomDarkColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    };

    setRandomColor = () => {
        const randomColor = this.randomDarkColor();
        document.documentElement.style.setProperty('--random-color', randomColor);
        localStorage.setItem('profile-icon-color', randomColor);
    };

    render() {
        try {
            if (this !== undefined && this.props.login === true) {
                return (
                    <header className="header">
                        <img className='logo' src={logo} alt='Pronetin' onClick={this.goHome} />
                        <div className='container'>
                            <button className='home' onClick={this.goHome}><i className="fa-solid fa-house"></i> Home</button>
                            <div className='profile-icon-container' ref={this.targetRef}>
                                <p className='profile-icon' onClick={this.toggleUserNav}>{this.props.name.charAt(0)}</p>
                                {this.state.UserNav && (
                                    <div className='user-nav'>
                                        <div className='container'>
                                            <div className='icon'>
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                            <div className='content'>
                                                <h3>{this.props.name}</h3>
                                                <h2>{this.props.admin_token}</h2>
                                            </div>
                                            <a href='/profile'><i className="fa-solid fa-pen-to-square"></i> Manage Your Profile</a>
                                        </div>

                                        <div className='blocks-container'>
                                            <a href='/organisation' className='blocks'><i className="fa-solid fa-file-signature"></i> Certificates</a>
                                            <a href='/feedbacks' className='blocks'><i className="fa-regular fa-message"></i> Feedbacks</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => e.preventDefault()}><i className="fa-solid fa-calendar-check"></i> Events</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => e.preventDefault()}><i className="fa-brands fa-nfc-symbol"></i> NFC</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => e.preventDefault()}><i className="fa-regular fa-address-card"></i> Resume</a>
                                            <a href='foo' className='blocks coming-soon' onClick={(e) => e.preventDefault()}><i className="fa-solid fa-users"></i> Social</a>
                                        </div>
                                        <button className='signout' onClick={this.Signout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Sign Out</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            this.state.warning_action.message !== "" ? <WarningAction data={this.state.warning_action} fetchWarningActionState={this.fetchWarningActionState} resumeFunction={this.resumeFunction} /> : ""
                        }

                    </header>
                );
            } else {
                return (
                    <header className="header">
                        <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                    </header>
                );
            }
        } catch (e) {
            return (
                <header className="header">
                    <a className='logo' href='/'><img className='logo' src={logo} alt='Pronetin' /></a>
                </header>
            );
        }
    }
}

export default HeaderComponent;