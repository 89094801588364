import './css/loading.css';
import React from 'react';

class WarningActionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleSendToParent.bind(this);
    }

    handleSendToParent = () => {
        this.props.fetchWarningActionState("")
    }

    componentDidMount() {
        if (this.props.data.autoclose === true) {
            setTimeout(this.handleSendToParent.bind(this), 5000)
        }
    }

    handleResume = () => {
        // Call the resolve function to resume the parent component function
        this.props.resumeFunction();
    };

    render() {
        return (
            <div className={this.props.data.type === 'success' ? 'warning-action success' : 'warning-action'}>
                <div className='relative'>
                    <div className='content'>
                        <p>{this.props.data.message}</p>
                        <p className='subtext'>{this.props.data.subtext}</p>
                        <div className='button-container'>
                            {this.props.data.proceed_action !== false && (this.props.data.proceed_function ? <button className='button proceed-btn' onClick={this.handleResume}>Continue</button> : <a className='button proceed-btn' href={this.props.data.proceed_action}>Proceed</a>)}
                            {this.props.data.back_action !== false ? <a className='button' href={this.props.data.back_action}>Back</a> : <button className='button' onClick={this.handleSendToParent}>Cancel</button>}
                        </div>
                    </div>
                    {this.props.data.close === true && (<button className='close-btn' onClick={this.handleSendToParent}><i className="fa-solid fa-x"></i></button>)}
                </div>
            </div>
        );
    }
}

export default WarningActionComponent;