import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from '../components/warning';

class TileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            fileList: [],
            elements: [],
            showLogs: false,
            logList: [],
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    componentDidMount() {
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'admin_get_pronetin_verification_requests').then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        elements: res.data.elements,
                    })
                }
            }
        })
    }

    listFiles = (org_email) => {
        axios.post(this.props.node_server_axios + 'admin_get_verification_files', {
            params: {
                org_email: org_email,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        fileList: res.data.fileList,
                    })
                }
            }
        })
    }

    acceptVerification = (org_email, org_id) => {
        axios.post(this.props.node_server_axios + 'accept_pronetin_verfication', {
            params: {
                org_email: org_email,
                org_id: org_id,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Accepted Pronetin Verfication for " + org_email,
                            type: "success",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.admin !== org_email),
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    rejectVerification = (org_email, org_id) => {
        axios.post(this.props.node_server_axios + 'reject_pronetin_verfication', {
            params: {
                org_email: org_email,
                org_id: org_id,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Rejected Pronetin Verfication for " + org_email,
                            type: "waring",
                            learnmore: false,
                            autoclose: false,
                        },
                        elements: this.state.elements.filter(item => item.admin !== org_email),
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: false,
                        },
                    })
                }
            }
        })
    }

    viewLogs = () => {
        if (!this.state.showLogs) {
            axios.post(this.props.node_server_axios + 'admin_get_pronetin_verification_logs').then(async (res) => {
                if (res.data !== undefined) {
                    if (res.data.message === 'success') {
                        this.setState({
                            logList: res.data.logList,
                        })
                    }
                }
            })
        }
        this.setState({
            showLogs: !this.state.showLogs,
        })
    }

    closeModal = (e) => {
        if (e.target.className === 'modal') {
            this.setState({
                fileList: [],
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin Verification<label>Organisation requests for pronetin verification</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            <button className='logs-btn' onClick={this.viewLogs}>Show Pronetin Verification {this.state.showLogs ? "Requests" : "Logs"}</button>
                            {this.state.showLogs ?
                                <div className='logs-table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Organisation Email</th>
                                                <th>Organisation ID</th>
                                                <th>Files</th>
                                                <th>Admin</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.logList.map((element, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{element.email}</td>
                                                        <td>{element.org_id}</td>
                                                        <td>{element.admin}</td>
                                                        <td><button onClick={() => this.listFiles(element.email)}>Get Files</button></td>
                                                        <td className={element.status}>{element.status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {this.state.fileList.length > 0 && (
                                        <div className='modal' onClick={this.closeModal}>
                                            <div className="content">
                                                <h2>Uploaded Files</h2>
                                                {this.state.fileList.map((item, index) => {
                                                    return (
                                                        <div className='file-block' key={index}><a href={"/" + item} target="_blank" rel="noreferrer">{item.split('/').pop()} <i className="fa-regular fa-circle-down"></i></a></div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                this.state.elements.map((element, index) => {
                                    return (
                                        <div className='action-container-1' key={index}>
                                            <div className='container'>
                                                <label>Organisation Details</label>
                                                <h2>{element.org_name}</h2>
                                                <p>{element.admin}</p>
                                                <p>{element.domain}</p>
                                            </div>
                                            <div className='container'>
                                                <label>Attached files</label>
                                                {this.state.fileList.length === 0 && (
                                                    <button className='file-block' onClick={() => this.listFiles(element.admin)}>View Files</button>
                                                )}
                                                {this.state.fileList.map((item, index) => {
                                                    console.log(item);
                                                    return (
                                                        <div className='file-block' key={index}><a href={"/" + item} target='_blank' rel="noreferrer">{item.split('/').pop()} <i className="fa-solid fa-arrow-up-right-from-square"></i></a><button><i className="fa-regular fa-trash-can"></i></button></div>
                                                    )
                                                })}
                                            </div>
                                            <div className='container'>
                                                <label>About</label>
                                                <p>Country : {element.country}</p>
                                                <p>Sector : {element.sector}</p>
                                                <p>Membership : {element.membership}</p>
                                                <p>Credits : {element.credits}</p>
                                            </div>
                                            <div className='container'>
                                                <label>Contact Info</label>
                                                <h3>Phone : {element.phone}</h3>
                                                <p>email : {element.admin}</p>
                                            </div>
                                            <div className='container'>
                                                <button onClick={() => this.acceptVerification(element.admin, element.org_id)}>Accept</button>
                                                <button onClick={() => this.rejectVerification(element.admin, element.org_id)}>Reject</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TileComponent;