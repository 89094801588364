import React from 'react';
import './css/element.css';
import axios from 'axios';

import Warning from './warning';
import ProgressLoader from '../components/progress_loader';

class DesignRequestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.fetchWarningState.bind(this);
        this.state = {
            warning: {
                message: "",
                type: "warning",
                learnmore: false,
                autoclose: false,
            },
            elements: [],
            modalState: false,
            batch_id: '',
            email_to_delete: '',
            itime: '',
            progress_loader: false,
            progress_opaque: false,
        };
    }

    fetchWarningState = (data) => {
        this.setState({
            warning: {
                message: data,
            }
        })
    }

    DeleteCertificatesByBatchAndTimeStamp = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'delete_certificates_by_batch_and_timestamp', {
            params: {
                batch_id: this.state.batch_id,
                itime: this.state.itime,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Certificates Deleted Sucessfully.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        batch_id: '',
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            }
        })
    }

    DeleteCertificatesByBatch = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'delete_certificates_by_batch', {
            params: {
                batch_id: this.state.batch_id,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Batch Deleted Sucessfully.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        batch_id: '',
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            }
        })
    }

    DeleteCertificatesByEmail = () => {
        this.setState({
            progress_loader: true,
        })
        axios.defaults.withCredentials = true;
        axios.post(this.props.node_server_axios + 'delete_certificates_by_email', {
            params: {
                email: this.state.email_to_delete,
            }
        }).then(async (res) => {
            if (res.data !== undefined) {
                if (res.data.message === 'success') {
                    this.setState({
                        warning: {
                            message: "Certificates in email Deleted Sucessfully.",
                            type: "success",
                            learnmore: false,
                            autoclose: true,
                        },
                        batch_id: '',
                        progress_loader: false,
                    })
                } else {
                    this.setState({
                        warning: {
                            message: "An error has occoured.",
                            type: "warning",
                            learnmore: false,
                            autoclose: true,
                        },
                        progress_loader: false,
                    })
                }
            }
        })
    }

    updateBatchId = (e) => {
        this.setState({
            batch_id: e.target.value,
        })
    }

    updateEmailToDelete = (e) => {
        this.setState({
            email_to_delete: e.target.value,
        })
    }

    updateItime = (e) => {
        this.setState({
            itime: e.target.value,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="element">
                    <div className="container">
                        <div className='header'>
                            <div className='container-left'>
                                <h1>Pronetin Verification<label>Organisation requests for pronetin verification</label></h1>
                            </div>
                        </div>
                        <div className='content-tile'>
                            <h1>Delete By Time</h1>
                            <input type='text' value={this.state.batch_id} onChange={this.updateBatchId} placeholder="Batch ID" />
                            <input type='number' value={this.state.itime} onChange={this.updateItime} placeholder="Itime" />
                            <button onClick={this.DeleteCertificatesByBatchAndTimeStamp}>Delete</button>
                            <h1>Delete Entire Batch</h1>
                            <input type='text' value={this.state.batch_id} onChange={this.updateBatchId} placeholder="Batch ID" />
                            <button onClick={this.DeleteCertificatesByBatch}>Delete</button>
                            <h1>Delete By Email Id</h1>
                            <input type='text' value={this.state.email_to_delete} onChange={this.updateEmailToDelete} placeholder="Email" />
                            <button onClick={this.DeleteCertificatesByEmail}>Delete</button>
                        </div>
                    </div>
                </div>
                {
                    this.state.warning.message !== "" ? <Warning data={this.state.warning} fetchWarningState={this.fetchWarningState} /> : ""
                }
                {
                    this.state.progress_loader && (<ProgressLoader opaque={this.state.progress_opaque} message={this.state.progress_message} />)
                }
            </React.Fragment>
        );
    }
}

export default DesignRequestComponent;