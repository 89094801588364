import React from 'react';
import staticImage from '../images/static/Group 252.png';
import Signin from '../components/signin';
import Header from '../components/header';

import '../components/css/main.css';

const SigninComponent = (props) => {
    return (
        <React.Fragment>
            <div className="signin">
                <Header />
                <img src={staticImage} alt="static" className='landing-img' />
                <Signin node_server_axios={props.node_server_axios} />
            </div>
        </React.Fragment>
    );

};

export default SigninComponent;